import axios from "axios";
import apis from "../apis";
import router from '../router/index';

// 设置store
let store: { kw_cookie: string; kw_secret: string; } | null = null;
export function setStore(_store: any){
  store = _store;
}
//创建实例
const instance = axios.create({
  baseURL: "/api/admin",
  timeout: 1000 * 10,
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  }
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    if(config?.baseURL === "/kuwo"){
      store && (document.cookie = `${store.kw_cookie};path=/`);
      store && (config.headers.Secret = store.kw_secret);
    }else{
      config.headers["x-token"] = localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const data = error?.response?.data;
    ElMessage.error(data?.msg || "请求错误");
    if([401,403].includes(data?.code)){
      localStorage.removeItem("token");
      setTimeout(() => {
        router.replace("/login");
      }, 1200);
    }
    return Promise.reject(error);
  }
);

// 请求方法
type ModuleNameType = keyof typeof apis;
function request<T extends ModuleNameType, U extends keyof (typeof apis)[T]>(
  moduleName: T,
  interfaceName: U,
  params?: ApiParamsType,
): Promise<ApiResponseType> {
  const { url, method, config } = apis[moduleName][interfaceName];
  switch (method) {
    case "get":
      return instance.get(url, {...config, params});
    case "post":
        return instance.post(url, params, {...config});
    case "put":
      return instance.put(url, params, {...config});
    case "delete":
      return instance.put(url, {...config, params});
    default:
      return instance.get(url, {...config, params});
  }
}

export default request;

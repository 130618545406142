import { createRouter, createWebHistory } from "vue-router";
// @ts-ignore
import routes from '~pages';
const router = createRouter({
  history: createWebHistory(),
  routes
});
router.beforeEach((to, _from ,next) => {
  const token = localStorage.getItem('token');
  const notCheckToken = ['/login', '/register'];
  if(to.path === '/login' && token) router.push('/admin');
  if(!token && !notCheckToken.includes(to.path)) router.replace('/login');
  next();
});

export default router;
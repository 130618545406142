const user: ApiModuleType = {
  sendVerificationCode: {
    url: "/user/sendVerificationCode",
    method: "post"
  },
  register: {
    url: "/user/register",
    method: "post"
  },
  login: {
    url: "/user/login",
    method: "post"
  }
}

export default user;
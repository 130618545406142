const playlist: ApiModuleType = {
  kuwoWinnowPlayList: {// 精选歌单
    url: "/classify/playlist/getRcmPlayList",
    method: "get",
    config: {
      baseURL: "/kuwo"
    }
  },
  kuwoTagPlayList: {
    url: "/classify/playlist/getTagPlayList",
    method: "get",
    config: {
      baseURL: "/kuwo"
    }
  },
  kuwoPlaylistSongList: {
    url: "/playlist/playListInfo",
    method: "get",
    config: {
      baseURL: "/kuwo"
    },
  },
  savePlayList: {
    url: "/playlist/add/list",
    method: "post"
  },
  getPlayList: {
    url: "/playlist/list",
    method: "get",
  },
  savePlaylistSong: {
    url: "/playlist/save/song/list",
    method: "post"
  }
}

export default playlist;
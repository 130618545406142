const singer: ApiModuleType = {
  kuwoSingerInfo: {
    url: "/artist/artist",
    method: "get",
    config: {
      baseURL: "/kuwo"
    }
  },
  saveSinger: {
    url: "/singer/save",
    method: "post"
  },
  list: {
    url: "/singer/list",
    method: "get",
  },
}

export default singer;
import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(router);
// @ts-ignore
Object.values(import.meta.glob("./modules/*.ts", {eager: true})).forEach(item => item.install?.({app,router}));
app.mount('#app');

import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";

// @ts-ignore
export const install = ({app}) => {
  const pinia = createPinia();
  pinia.use(createPersistedState({
    serializer: {
      serialize: JSON.stringify,
      deserialize: JSON.parse,
    }
  }));
  app.use(pinia);
}
export default {
  searchKuwoLeaderboardList: {
    url: "/bang/bang/bangMenu",
    method: "get",
    config: {
      baseURL: "/kuwo"
    }
  },
  addLeaderboard: {
    url: "/rank/add",
    method: "post"
  },
  searchRank: {
    url: "/rank/search",
    method: "get"
  },
  searchRankSongListByKuwoRankId: {
    url: "/bang/bang/musicList",
    method: "get",
    config: {
      baseURL: "/kuwo"
    }
  },
  saveRankSong: {
    url: "/rank/updateSong",
    method: "post"
  },
  rankSongList: {
    url: "/rank/song/list",
    method: "get"
  },
} as ApiModuleType;
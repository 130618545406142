import { defineStore} from 'pinia';
// @ts-ignore
export const useStore = defineStore("jade-kylin-admin", {
  state: () => ({
    kw_secret: '',
    kw_cookie: '',
    kw_error_count: 0,
    kw_songList: [] as KuwoSongType[],
    kw_currentRankId: 0,
    kw_currentPlaylistId: 0,
    kw_notSaveDbSinger: [] as localSingerType[],
    kw_notUpdateSongSinger: [] as localSingerType[],
    kw_notSavePlaylistSongList: [] as KuwoSongType[],
  }),
  getters: {},
  actions: {
    setKuwoHeader(params: KuwoHeaderType){// 设置酷我请求头
      this.kw_cookie = params.cookie;
      this.kw_secret = params.secret;
    },
    setKuwoErrorCount(count: number){// 设置酷我接口查询失败的次数，10次错误，重设请求头
      this.kw_error_count = count;
    },
    setKuwoSongList(list: KuwoSongType[]){// 更新排行榜歌曲到本地
      list.forEach(item => {
        const song = this.kw_songList.find(i => i.rid == item.rid);
        if(!song){
          // 歌曲不存在，添加到本地存储
          this.kw_songList.push(item);
        }
      });
    },
    resetKuwoRankSongList(){// 重置排行榜歌曲列表
      this.kw_songList = [];
    },
    setKuwoCurrentRankId(id: number){// 设置当前更新的排行榜id
      this.kw_currentRankId = id;
    },
    setKuwoNotSaveDbSinger(list: localSingerType[]){ // 添加排行榜中，数据库里面没有的歌手到本地
      list.forEach(item => {
        const singer = this.kw_notSaveDbSinger.find(i => i.id == item.id);
        if(!singer){
          // 歌手不存在，添加到本地存储
          this.kw_notSaveDbSinger.push(item);
        }
      });
    },
    removeKuwoNotSaveDbSinger(id: number){// 从本地删除已经保存到数据库中的歌手
      this.kw_notSaveDbSinger = this.kw_notSaveDbSinger.filter(item => item.id !== id);
    },
    setKuwoNotUpdateSongSiner(item: localSingerType){// 添加未更新歌曲的歌手
      const singer = this.kw_notUpdateSongSinger.find(i => i.id === item.id);
      if(!singer){
        // 歌手不存在，添加到本地存储
        this.kw_notUpdateSongSinger.push(item);
      }
    },
    removeKuwoNotUpdateSongSiner(id: number){// 从未更新歌手列表中移除已更新了歌曲的歌手
      this.kw_notUpdateSongSinger = this.kw_notUpdateSongSinger.filter(item => item.id !== id)
    },
    setKuwoCurrentPlaylistId(id: number){// 设置当前更新的歌单id
      this.kw_currentPlaylistId = id;
    },
    setKuwoNotSavePlaylistSongList(list: KuwoSongType[]){//保存当前更新的歌单歌曲到本地
      list.forEach(item => {
        const song = this.kw_notSavePlaylistSongList.find(i => i.rid == item.rid);
        if(!song){
          this.kw_notSavePlaylistSongList.push(item);
        }
      });
    },
    removeKuwoNotSavePlaylistSongList(){// 重置
      this.kw_notSavePlaylistSongList = [];
    }
  },
  persist: {
    key: "jade-kylin-admin",
    storage: window.localStorage,
    paths: ["kw_secret", "kw_cookie", "kw_error_count","kw_songList","kw_currentRankId","kw_notSaveDbSinger","kw_notUpdateSongSinger","kw_currentPlaylistId", "kw_notSavePlaylistSongList"]
  },
});
const song: ApiModuleType = {
  single: {
    url: "/api/song/single",
    method: "get"
  },
  album: {
    url: "/api/song/album",
    method: "get"
  },
  listBySingerId: {
    url: "/artist/artistMusic",
    method: "get",
    config: {
      baseURL: "/kuwo"
    }
  },
  saveSong: {
    url: "/song/list/save",
    method: "post",
  }
}

export default song;
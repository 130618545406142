<template>
  <router-view />
</template>
<script setup lang="ts">
import { useStore } from './store';
import { setStore } from './utils/request';
const store = useStore();
onMounted(() => {
  setStore(store);
});

watch(() => store, () => {
  setStore(store);
}, { deep: true});
</script>
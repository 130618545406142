import user from "./user";
import singer from "./singer";
import song from "./song";
import leaderboard from "./leaderboard";
import playlist from "./playlist";

export default {
  user,
  singer,
  song,
  leaderboard,
  playlist
}